.productContainer {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.productBody {
    width: 100%;
    // max-width: 500px;
}

.productSection {
    width: 100%;
    flex-wrap: wrap;
    // justify-content: space-between;
}

@media only screen and (max-width: 767px) {

}
