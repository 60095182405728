.productCard {
    width: 400px;
    border-radius: 8px;
}

.width80p {
    width: 80px;
}

.productImage {
    width: 80px;
    height: 80px;
    border-radius: 8px;
}

.editButton {
    width: 100%;
    border-radius: 8px;
}

@media only screen and (max-width: 767px) {

}
