@tailwind base;
@tailwind components;
@tailwind utilities;

body::-webkit-scrollbar {
    width: 1em;
  }
   
  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
   
  body::-webkit-scrollbar-thumb {
    background-color: #f4f4f4;
    outline: 1px solid #fbf4f9;
  }
