.orderTab {
    width: 100%;
    position: fixed;
    top: 56px;
    // margin-top: 56px;
    // flex: 1;
    display: flex;
    align-items: center;
}

.tabBox {
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 767px) {

}
