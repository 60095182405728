.orderContainer {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.orderBody {
    width: 100%;
    margin-top: 56px;
    // max-width: 500px;
}

.orderSection {
    width: 100%;
    flex-wrap: wrap;
    // justify-content: space-between;
}

@media only screen and (max-width: 767px) {

}
