
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000;
    opacity: 0.6;
    z-index: 1030;
}

.modalBody {
    position: fixed;
    top: 10vh;
    background: #fff;
    z-index: 1030;
    border-radius: 10px;
    max-height: 70vh;
    overflow: auto;
}


.md, .lg, .sm {
    width: 90%;
    left: 5%;
}

.modalMain {
    display: none;
}
.visible {
    display: block;
}

/* For tab & laptop */

@media only screen and (min-width:767px) { 

    .md {
        width: 50%;
        left: 25%;
    }
    .lg {
        width: 80%;
        left: 10%;
    }
    .sm {
        width: 30%;
        left: 35%;
    }
}