.modalContainer {
    border-radius: 16px;
    overflow: hidden;
}

.textInputBox {
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    outline: none;
}

.saveButton {
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    outline: none;
}
