.authContainer {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
}

.authBody {
    width: 100%;
    max-width: 500px;
}

.textInputBox {
    width: 100%;
    padding: 8px;
    // border-color: #ffffff;
    border-radius: 8px;
    outline: none;
}

.loginButton {
    width: 100%;
    background-color: #38b823;
    border-radius: 8px;
    padding: 8px;
}

@media only screen and (max-width: 767px) {

    // .wrapperBody {
    //     width: 100%;
    // }
}
