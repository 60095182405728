.switchInput {
    display: none;
  }
  .switchLabel {
    position: relative;
    display: inline-block;
    font-weight: 600;
    text-align: left;
    padding: 4px 16px 4px 44px;
  }
  .switchLabel:before, .switchLabel:after {
    content: "";
    position: absolute;
    margin: 0;
    outline: 0;
    top: 50%;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .switchLabel:before {
    left: 1px;
    width: 34px;
    height: 14px;
    background-color: #9E9E9E;
    border-radius: 8px;
  }
  .switchLabel:after {
    left: 0;
    width: 20px;
    height: 20px;
    background-color: #FAFAFA;
    border-radius: 50%;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);
  }
  .switchInput:checked + .switchLabel:before {
    background-color: #AAAAD9;
  }
  .switchInput:checked + .switchLabel:after {
    background-color: #2b2ba0;
    -ms-transform: translate(80%, -50%);
    -webkit-transform: translate(80%, -50%);
    transform: translate(80%, -50%);
  }
  
  .switchInput:checked + .switchLabel {
    color: #2b2ba0;
  }
  