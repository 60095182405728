.brandContainer {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.brandBody {
    width: 100%;
}

.brandSection {
    width: 100%;
    max-width: 500px;
    flex-wrap: wrap;
    // justify-content: space-between;
}

@media only screen and (max-width: 767px) {

}
