.sectionBox {
    border-radius: 8px;
}

.actionButton {
    width: 100%;
    border-radius: 16px;
}

.offerBox {
    border-radius: 16px;
    border-width: 2px;
    // border-color: ;
}

.modalContainer {
    border-radius: 16px;
    overflow: hidden;
}
