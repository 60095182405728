.brandCard {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    word-wrap: break-word;
    max-width: 500px;
}

@media only screen and (max-width: 767px) {

}
