.wrapperWithHeaderContainer {
    position: relative;
    top: 56px;
    width: 100%;
    height: calc(100vh - 56px);
    background-color: #d3d3d3;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    position: relative;
}

.wrapperWithoutHeaderContainer {
    width: 100%;
    height: 100vh;
    background-color: #d3d3d3;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    position: relative;
}

.mainPageBody {
    width: 100%;
    display: flex;
    justify-content: center;
}

.wrapperDefaultBody {
    width: 80%;
    max-width: 1440px;
    height: 100% !important;
}

.wrapperFullWidthBody {
    width: 100%;
    max-width: 1440px;
    height: 100% !important;
}

@media only screen and (max-width: 767px) {

    .wrapperDefaultBody {
        width: 100%;
    }
}
